<template>
    <div class="cd-body p-4 sm:p-8 mb-base flex flex-col gap-8">
        <div>
            <h4>{{ $t('setWord.title') }}</h4>
            <p>{{ $t('setWord.desc') }}</p>
        </div>
        <div class="flex flex-col gap-4">
            <div class="flex justify-between">
                <!-- 選擇類型 select -->
                <vs-select v-model="code">
                    <vs-select-item :key="index" :value="type.code" :text="$common.getLangDB(type)" v-for="(type, index) in types" v-if="type.is_canword" />
                </vs-select>
                <!-- 匯入 button -->
                <vs-button class="cd-button-2w-icon rounded" color="warning" icon-pack="feather" icon="icon-file-plus" @click="DialogVisibleWord = true">{{ $t('setWord.import') }}</vs-button>
            </div>
            <!-- 新增常用字句 textarea -->
            <div>
                <vs-textarea class="w-full cd-emr-textarea bg-white" :placeholder="$t('setWord.addWord')" v-model="word" rows="6" />
            </div>
            <!-- 新增常用字句 button -->
            <div class="flex justify-end">
                <vs-button class="cd-button-4w rounded" :disabled="word == ''" @click="createWord">{{ $t('setWord.addWord') }}</vs-button>
            </div>
        </div>
        <!-- 常用字句List -->
        <div class="grid grid-cols-1 gap-2 items-stretch">
            <CanWordItem v-for="(word, index) in words" :key="index" :word="word"></CanWordItem>
        </div>
        <!-- <vs-row>
            <vs-col class="cd-form-group">
                <vs-list>
                    <vs-list-item :class="windowWidth < 768 ? 'cd-list-item-sm w-full' : ''" :title="word.word" v-for="(word, index) in words" :key="index">
                        <vs-col vs-type="flex" vs-justify="flex-end">
                            <feather-icon icon="Edit2Icon" class="m-1 cursor-pointer hover:text-primary" @click="showEditDialog(word)"></feather-icon>
                            <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="delWord(word)"></feather-icon>
                        </vs-col>
                    </vs-list-item>
                    <vs-list-item v-show="words == ''" :title="$t('setWord.noData')"></vs-list-item>
                </vs-list>
            </vs-col>
        </vs-row> -->

        <!-- 匯入視窗 -->
        <el-dialog :visible.sync="DialogVisibleWord" :width="windowWidth > 768 ? '50%' : '80%'" center>
            <!--步驟-->
            <el-steps :active="importwordstep">
                <el-step :title="$t('setWord.download')" icon="el-icon-download"> </el-step>
                <el-step :title="$t('setWord.uploadFile')" icon="el-icon-upload"> </el-step>
                <el-step :title="$t('setWord.previewData')"> </el-step>
                <el-step :title="$t('setWord.finish')"> </el-step>
            </el-steps>
            <!--下載模板-->
            <div style="text-align: center; margin: 20px 0" v-show="this.importwordstep == 1">
                <h1>
                    <a href="https://upload.curdoctor.com.tw/excel_template/upload_setword_example.xlsx" style="color: black; text-decoration: none"> {{ $t('setWord.downloadExample') }}<el-button type="info" icon="el-icon-download" circle></el-button> </a>
                </h1>
            </div>
            <!--上傳文件-->
            <div style="text-align: center; margin: 20px 0" v-show="this.importwordstep == 2">
                <el-alert :title="$t('setWord.onlyExcelFile')" type="warning" show-icon style="margin: 20px 0"></el-alert>
                <el-upload class="upload-demo" drag action="" :multiple="false" :auto-upload="false" :show-file-list="false" :on-change="ChangeUpload">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text" v-html="$t('setWord.dragFile')"></div>
                    <div class="el-upload__tip" slot="tip"></div>
                </el-upload>
            </div>
            <!--預覽資料-->
            <div style="text-align: center; margin: 20px 0" v-show="this.importwordstep == 3">
                <el-table :data="previewworddata" height="250" border style="width: 100%">
                    <el-table-column prop="type" :label="$t('setWord.doctorSOAP')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type == 's' || scope.row.type == 'o' || scope.row.type == 'memop' || scope.row.type == 'wd' || scope.row.type == 'diet'">
                                <div style="text-align: center; font-size: 15px">{{ scope.row.type | type }}</div>
                            </div>
                            <div v-else>
                                <div style="text-align: center; font-size: 15px">{{ scope.row.type }}</div>
                                <el-alert :title="$t('setWord.noDoctorSOAP')" type="warning" show-icon style="margin: 20px 0"></el-alert>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="word" :label="$t('setWord.title')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.word == ''">
                                <div style="text-align: center; font-size: 15px">{{ scope.row.word }}</div>
                                <el-alert :title="$t('message.data_not_input')" type="warning" show-icon style="margin: 20px 0"></el-alert>
                            </div>
                            <div v-else>
                                <div style="text-align: center; font-size: 15px">{{ scope.row.word }}</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--完成-->
            <div style="text-align: center; margin: 20px 0" v-show="this.importwordstep == 4">
                <h1>
                    {{ $t('message.upload_success') }}
                </h1>
            </div>
            <!--標籤-->
            <div style="text-align: center">
                <vs-button :size="windowWidth > 576 ? '' : 'small'" v-show="this.importwordstep == 2 || this.importwordstep == 3" @click="previousstep">{{ $t('setWord.prevStep') }}</vs-button>
                <vs-button :size="windowWidth > 576 ? '' : 'small'" v-show="this.importwordstep == 1" @click="nextstep">{{ $t('setWord.nextStep') }}</vs-button>
                <vs-button :size="windowWidth > 576 ? '' : 'small'" v-show="this.importwordstep == 3" @click="importword">{{ $t('setWord.importMultiple') }}</vs-button>
                <vs-button :size="windowWidth > 576 ? '' : 'small'" v-show="this.importwordstep == 4" @click="closeDialogVisibleWord">{{ $t('popup.confirm') }}</vs-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { createWord } from '@/api/user'
import XLSX from 'xlsx'
import { mapState } from 'vuex'
import CanWordItem from '@/components/CanWord/CanWordItem.vue'
export default {
    components: { CanWordItem },
    data() {
        return {
            // val: '',
            // close: '',
            // editword: '',
            // editid: 0,
            // delid: 0,
            // activeEdit: false,
            code: 'wd',
            // hovers: [
            //     { text: this.$t('setWord.soap_S'), value: 's' },
            //     { text: this.$t('setWord.soap_O'), value: 'o' },
            //     { text: this.$t('setWord.soap_Memop'), value: 'memop' },
            //     { text: this.$t('setWord.soap_WD'), value: 'wd' },
            //     { text: this.$t('setWord.diet'), value: 'diet' },
            // ],
            word: '',
            //常用字句多筆匯入
            DialogVisibleWord: false,
            importwordstep: 1,
            previewworddata: null,
            // setwordPage: 1,
            // setwordTotal: 0,
            // page: 1,
        }
    },
    // mounted() {
    //     this.fetchWords()
    //     this.pageChange(1)
    // },
    filters: {
        type(type) {
            const statusMap = {
                s: '主觀項目 S',
                o: '客觀項目 O',
                memop: '醫事人員建議',
                wd: '文字交談',
                diet: '飲食建議',
            }
            return statusMap[type]
        },
    },
    computed: {
        ...mapState(['windowWidth']),
        types() {
            return this.$store.state.words
        },
        words() {
            const found = this.types.find((item) => item.code === this.code)
            return found ? found.words : []
        },
        //     wordsByCode: (state) => (code) => {
        //     const found = state.words.find((item) => item.code === code)
        //     return found ? found.words.map((w) => w.word) : []
        // },
        // windowWidth() {
        //     var windowwidth = this.$store.state.windowWidth
        //     return windowwidth
        // },
        // words() {
        //     let result
        //     if (this.selecttype == 's') {
        //         result = this.$store.getters.wordS
        //     } else if (this.selecttype == 'o') {
        //         result = this.$store.getters.wordO
        //     } else if (this.selecttype == 'memop') {
        //         result = this.$store.getters.wordMemo
        //     } else if (this.selecttype == 'note') {
        //         result = this.$store.getters.wordNote
        //     } else if (this.selecttype == 'wd') {
        //         result = this.$store.getters.wordWD
        //     } else if (this.selecttype == 'diet') {
        //         result = this.$store.getters.wordDiet
        //     }
        //     return result
        // },
    },
    methods: {
        // 開啟刪除視窗
        // delWord(item) {
        //     this.delid = item.id
        //     this.$vs.dialog({
        //         type: 'confirm',
        //         color: 'danger',
        //         title: '刪除',
        //         text: this.$t('message.confirm_delete_record'),
        //         acceptText: '刪除',
        //         accept: this.acceptDel,
        //     })
        // },
        // // 開啟更新視窗
        // showEditDialog(item) {
        //     this.editword = item.word
        //     this.checkword = item.word
        //     this.editid = item.id
        //     this.activeEdit = true
        // },
        // // 更新常用字句
        // acceptEdit() {
        //     var _self = this
        //     if (_self.editword == _self.checkword) {
        //         this.notify('danger', this.$t('setWord.waring'), '')
        //         return false
        //     }
        //     _self.$vs.loading()
        //     _self.$store
        //         .dispatch('editWord', { id: _self.editid, word: _self.editword, type: _self.code })
        //         .then((res) => {
        //             _self.fetchWords()
        //         })
        //         .then(() => {
        //             _self.activeEdit = false
        //             _self.editword = ''
        //             _self.$vs.loading.close()
        //         })
        // },
        // // 刪除常用字句
        // acceptDel() {
        //     var _self = this
        //     _self.$vs.loading()
        //     _self.$store.dispatch('delWord', { id: _self.delid, type: _self.code }).then((res) => {
        //         if (res.data.status == 'OK') {
        //             _self.notify('grey', '', _self.$t('message.delete_success'))
        //         } else {
        //             _self.notify('danger', '', _self.$t('message.delete_error'))
        //         }
        //         _self.fetchWords()
        //         _self.$vs.loading.close()
        //     })
        // },
        // 新增常用字句
        // addWord() {
        //     var _self = this
        //     _self.$vs.loading()
        //     let arrayPayload = [{ type: _self.code, word: _self.word }]
        //     _self.$store.dispatch('addWord', arrayPayload).then(() => {
        //         _self.word = ''
        //         _self.fetchWords()
        //         _self.$vs.loading.close()
        //     })
        // },
        // 新增常用字句
        async createWord() {
            try {
                const payload = {
                    type: this.code,
                    word: this.word,
                }
                await createWord(payload)
                this.$store.dispatch('notify', { color: 'grey', title: '新增成功' })
                this.$store.dispatch('getHisConfig')
                this.word = ''
            } catch (error) {
                console.error('刪除常用字句失敗', error)
            }
        },
        // 取常用字句
        // fetchWords() {
        //     var _self = this
        //     _self.$vs.loading()
        //     _self.$store.dispatch('fetchWords', _self.selecttype + '/' + _self.setwordPage).then((res) => {
        //         _self.setwordTotal = res.data.total
        //         _self.$vs.loading.close()
        //     })
        // },
        // 選取常用字句類型
        // selectType() {
        //     this.fetchWords()
        // },
        // 多筆匯入上一部按鈕
        previousstep() {
            this.importwordstep--
        },
        // 多筆匯入下一步按鈕
        nextstep() {
            this.importwordstep++
        },
        // 檔案上傳
        ChangeUpload(event) {
            let _self = this
            var testmsg = event.name.substring(event.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'xls'
            const extension2 = testmsg === 'xlsx'
            if (!extension && !extension2) {
                _self.notify('danger', '', _self.$t('message.upload_file_error'))
                return false
            }
            var files = event.raw
            var reader = new FileReader()
            reader.onload = function (e) {
                var data = new Uint8Array(e.target.result)
                var workbook = XLSX.read(data, { type: 'array' })
                let sheetName = workbook.SheetNames[0]
                /* DO SOMETHING WITH workbook HERE */
                let worksheet = workbook.Sheets[sheetName]
                _self.previewworddata = XLSX.utils.sheet_to_json(worksheet)
                _self.nextstep()
            }
            reader.readAsArrayBuffer(files)
        },
        // 多筆匯入
        importword() {
            var _self = this
            _self.$vs.loading()
            _self.$store.dispatch('addWord', _self.previewworddata).then(() => {
                _self.fetchWords()
                _self.$vs.loading.close()
            })
            _self.nextstep()
        },
        // 關閉匯入視窗
        closeDialogVisibleWord() {
            this.DialogVisibleWord = false
            this.importwordstep = 1
        },
        // 換頁
        // pageChange(page) {
        //     this.setwordPage = page
        //     this.fetchWords()
        //     window.scrollTo(0, 0)
        // },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
    },
}
</script>
<style scope>
.vs-list--title {
    font-weight: 400;
    word-break: break-word;
}
</style>
