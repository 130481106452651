<template>
    <div class="flex justify-between items-center gap-4 p-4 border rounded shadow bg-white" style="border: #999 1px solid">
        <div class="truncate">
            <span>{{ word.word }}</span>
        </div>
        <div class="flex gap-4">
            <div class="flex items-center gap-1 whitespace-no-wrap text-primary cursor-pointer hover:font-semibold" @click="openEdit()">
                <feather-icon icon="Edit2Icon" svgClasses="h-4 w-4"></feather-icon>
                <span v-if="windowWidth >= 576">編輯</span>
            </div>
            <div class="flex items-center gap-1 whitespace-no-wrap cd-text-muted cursor-pointer hover:text-danger hover:font-semibold" @click="openDelete()">
                <feather-icon icon="Trash2Icon" svgClasses="h-4 w-4"></feather-icon>
                <span v-if="windowWidth >= 576">刪除</span>
            </div>
        </div>
        <!-- 編輯視窗 -->
        <vs-popup :title="$t('setWord.edit')" :active.sync="activeEdit" @close="closeEdit">
            <vs-textarea class="w-full cd-emr-textarea" :placeholder="$t('setWord.addWord')" v-model="text" rows="6" />
            <div class="flex flex-wrap justify-end gap-4">
                <vs-button class="cd-button-2w rounded" @click="updateWord">{{ $t('popup.save') }}</vs-button>
                <vs-button class="cd-button-2w rounded" type="border" @click="closeEdit">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
        <!-- 刪除確認視窗 -->
        <vs-popup :title="'刪除'" :active.sync="activeDelete" @close="closeDelete">
            <span>{{ $t('message.confirm_delete_record') }}</span>
            <div class="flex flex-wrap justify-end gap-4">
                <vs-button class="cd-button-2w rounded" color="danger" @click="deleteWord">刪除</vs-button>
                <vs-button class="cd-button-2w rounded" type="border" @click="closeDelete">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import { updateWord, deleteWord } from '@/api/user'
import { mapState } from 'vuex'
export default {
    props: {
        word: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            activeEdit: false,
            activeDelete: false,
            text: '',
        }
    },
    computed: {
        ...mapState(['windowWidth']),
    },
    methods: {
        openEdit() {
            this.text = this.word.word
            this.activeEdit = true
        },
        closeEdit() {
            this.activeEdit = false
        },
        openDelete() {
            this.activeDelete = true
        },
        closeDelete() {
            this.activeDelete = false
        },
        // 刪除常用字句
        async deleteWord() {
            try {
                const payload = {
                    word_id: this.word.id,
                }
                await deleteWord(payload)
                this.$store.dispatch('notify', { color: 'grey', title: '刪除成功' })
                this.$store.dispatch('getHisConfig')
                this.closeDelete()
            } catch (error) {
                console.error('刪除常用字句失敗', error)
            }
        },
        // 更新常用字句
        async updateWord() {
            try {
                this.text = _.trim(this.text)
                if (!this.text) {
                    this.$store.dispatch('notify', { color: 'danger', title: '請填寫內容' })
                    return
                }
                const payload = {
                    word_id: this.word.id,
                    word: this.text,
                }
                await updateWord(payload)
                this.$store.dispatch('notify', { color: 'grey', title: '編輯成功' })
                this.$store.dispatch('getHisConfig')
                this.closeEdit()
            } catch (error) {
                console.error('更新常用字句失敗', error)
            }
        },
    },
}
</script>
